export const Urls = {
    BaseURL: 'cdn-base-url',
    
    // Page ids
    page_messages: '240',
    pa_dashboard: '239',
    vacancies_search: '241',
    homepage: '242',
    pa_profile: '244',
    pa_search: '243',
    employer_dashboard: '245',
    vacancy_profile: '246',
    about: '247',
    register: '251',
    pa_registration: '253',
    powys_dashboard: '262',
    powys_contact:'265',

    // homepage_hants: '242',
    page_hants_messages: '8',
    // pa_hants_dashboard: '6',
    pa_search_hants: '9',
    pa_profile_hants: '10',
    // employer_hants_dashboard: '7',
    vacancies_hants_search: '12',
    vacancy_hants_profile: '11',
    direct_provider_registration_hants: '13',
    // program_id_hants: '6ece4325-a17c-41ef-a342-b10100fab0a0',
    program_id_hants: '914da10d-7c6d-47f4-906f-b20200daf5bb',
    register_hants: '14',
    dashboard_hants: '15',
    contact_form_hants_id: '10',
    info_hants: '18',
    contact_us_page: '17',

    // pa_hants_registration: '253',

    // Form ids
    form_equipmentHouse: '996',    

    // East Riding
    page_messages_er: '266',
    pa_dashboard_er: '276',
    vacancies_search_er: '267',
    homepage_er: '268',
    pa_profile_er: '269',
    pa_search_er: '270',
    employer_dashboard_er: '276',
    vacancy_profile_er: '271',
    about_er: '272',
    register_er: '274',
    pa_registration_er: '275',
    dashboard_er: '276',
    contact_form_er: '1074',
    program_id_er: '1ae56521-c651-408b-adf8-b13100e0d601',

    // Hampshire Live attributes

    availability: [
        {
            "name": "Flexible",
            "guid": "D339D8CA-381F-483F-84AE-B0C300FA2201"
        },
        {
            "name": "Monday to Friday (during evening)",
            "guid": "5A0C4722-E8D8-458E-B83B-B0C300FA2201"
        },
        {
            "name": "Monday to Friday (early morning)",
            "guid": "86F93206-0CEC-4D65-ABF7-B0C300FA2201"
        },
        {
            "name": "Monday to Friday (school hours)",
            "guid": "39C52E83-F4F09-4D3D-B8B8-B0C300FA2201"
        },
        {
            "name": "Sleep ins",
            "guid": "39F4930A-246E-4C79-89DC-B0C300FA2201"
        },
        {
            "name": "Waking nights",
            "guid": "2106E28E-6290-4F4C-BB64-B0C300FA2201"
        },
        {
            "name": "Weekends",
            "guid": "987113C2-A088-4E29-9323-B0C300FA2201"
        }
    ],

    details: [
      {
          "name": "Full driving licence",
          "guid": "6B6830C1-BF42-479A-A959-B0C300F5B52A"
      },
      {
          "name": "Willing to support with parenting duties",
          "guid": "1EE6E392-6A9A-467E-9FAD-B0C300F5B52A"
      },
      {
          "name": "Willing to use own vehicle if suitable",
          "guid": "9A90E3A1-20AA-424C-8437-B0C300F5B52A"
      },
      {
          "name": "Willing to work in smoking environment",
          "guid": "9862B1BC-C441-4970-BB54-B0C300F5B52A"
      },
      {
          "name": "Willing to work with pets",
          "guid": "949D0A0E-CB80-406B-8494-B0C300F5B52A"
      },
      {
          "name": "Would consider being a live-in carer",
          "guid": "A07467A4-EA74-474B-9554-B0C300F5B52A"
      },
      {
          "name": "Would consider jobs for fewer hours",
          "guid": "5355E1D7-2528-4870-8775-B0C300F5B52A"
      }
  ],

    locations: [
      {
          "name": "Aldershot",
          "guid": "52CDB0F1-ED7E-4514-8D09-B0C300F2F45F"
      },
      {
          "name": "Alton",
          "guid": "CF12C367-61DF-4795-818D-B0C300F2F45F"
      },
      {
          "name": "Alverstoke",
          "guid": "DDFC81B0-9E9F-4F5A-B653-B0C300F2F45F"
      },
      {
          "name": "Andover",
          "guid": "CC51FAF2-0663-4D0D-91D0-B0C300F2F45F"
      },
      {
          "name": "Ashurst",
          "guid": "3D4E203C-908E-4CF0-A9C1-B0C300F2F45F"
      },
      {
          "name": "Barton on Sea",
          "guid": "E17395E5-973E-4325-BFE7-B0C300F2F45F"
      },
      {
          "name": "Basingstoke",
          "guid": "387CE1CB-45AB-4854-B305-B0C300F2F45F"
      },
      {
          "name": "Bishop Waltham",
          "guid": "51E4825B-A7E5-4059-9726-B0C300F2F45F"
      },
      {
          "name": "Bordon",
          "guid": "5B867761-966D-4315-A0E2-B0C300F2F45F"
      },
      {
          "name": "Chandlers Ford",
          "guid": "E49C511A-6AA8-4998-B751-B0C300F2F45F"
      },
      {
          "name": "Eastleigh",
          "guid": "5F26E28B-33E5-4D28-8BC8-B0C300F2F45F"
      },
      {
          "name": "Emsworth",
          "guid": "667DE04D-EB47-4658-9D1D-B0C300F2F45F"
      },
      {
          "name": "Fareham",
          "guid": "AFBB3E5E-DD9C-45A3-8817-B0C300F2F45F"
      },
      {
          "name": "Farnborough",
          "guid": "29C244EA-5363-4840-8E35-B0C300F2F45F"
      },
      {
          "name": "Farnham",
          "guid": "B8388E7E-8FA1-4A73-8F49-B0C300F2F45F"
      },
      {
          "name": "Fleet",
          "guid": "7504A1AB-969E-46DA-B0C2-B0C300F2F45F"
      },
      {
          "name": "Fordingbridge",
          "guid": "026084B3-86BD-40D3-8E9D-B0C300F2F45F"
      },
      {
          "name": "Gosport",
          "guid": "D506B7EE-B136-4126-9B97-B0C300F2F45F"
      },
      {
          "name": "Havant",
          "guid": "16DE1C05-98EC-4CF5-A045-B0C300F2F45F"
      },
      {
          "name": "Hayling Island",
          "guid": "6AB46437-6D10-443D-9423-B0C300F2F45F"
      },
      {
          "name": "Hedge End",
          "guid": "EA1EA60D-341E-43C8-8EAA-B0C300F2F45F"
      },
      {
          "name": "Horndean",
          "guid": "56F1DF00-CC8D-45DB-BED3-B0C300F2F45F"
      },
      {
          "name": "Hythe",
          "guid": "D5F446E8-5699-4C90-B264-B0C300F2F45F"
      },
      {
          "name": "Lee on Solent",
          "guid": "C27A5217-23A9-49BB-8026-B0C300F2F45F"
      },
      {
          "name": "Liphook",
          "guid": "66DDBF7B-0D64-4004-901E-B0C300F2F45F"
      },
      {
          "name": "Liss",
          "guid": "E0C435B0-33BE-48B8-980A-B0C300F2F45F"
      },
      {
          "name": "Locks Heath",
          "guid": "5D04B3D9-82FC-4F9D-B939-B0C300F2F45F"
      },
      {
          "name": "Lymington",
          "guid": "2C4FCA01-319C-477E-9B61-B0C300F2F45F"
      },
      {
          "name": "Milford on Sea",
          "guid": "3EA16241-6AD8-40C5-B75B-B0C300F2F45F"
      },
      {
          "name": "Netley Abbey",
          "guid": "6A84A0E1-56B3-4A0D-A7DD-B0C300F2F45F"
      },
      {
          "name": "New Milton",
          "guid": "6AC7F695-1074-419B-A66D-B0C300F2F45F"
      },
      {
          "name": "Petersfield",
          "guid": "4DE5840D-D447-43EF-964C-B0C300F2F45F"
      },
      {
          "name": "Portsmouth",
          "guid": "6E89BF25-8A09-4E31-A5D5-B0C300F2F45F"
      },
      {
          "name": "Ringwood",
          "guid": "206AEF23-FDCC-4E27-A67F-B0C300F2F45F"
      },
      {
          "name": "Romsey",
          "guid": "CE329564-2DE5-4697-8A45-B0C300F2F45F"
      },
      {
          "name": "Southampton CENTRAL",
          "guid": "5E052FD9-8939-421C-889D-B0C300F2F45F"
      },
      {
          "name": "Southampton EAST",
          "guid": "05B6519D-0E76-4AE0-B4AA-B0C300F2F45F"
      },
      {
          "name": "Southampton WEST",
          "guid": "ED9282F4-2132-47EE-8351-B0C300F2F45F"
      },
      {
          "name": "Stockbridge Totton",
          "guid": "34917F85-717F-4B92-AF08-B0C300F2F45F"
      },
      {
          "name": "Waterlooville",
          "guid": "5A8B8B45-800B-4B26-AD20-B0C300F2F45F"
      },
      {
          "name": "West End",
          "guid": "9680B059-0F3D-4EBE-8D77-B0C300F2F45F"
      },
      {
          "name": "Wickham",
          "guid": "34C57860-3585-446C-B023-B0C300F2F45F"
      },
      {
          "name": "Winchester",
          "guid": "C1ABF043-BB1F-467B-8473-B0C300F2F45F"
      }
  ],

    tasks: [
        {
            "name": "Days out and support with social activities",
            "guid": "e1cad26e-8903-499f-83f7-b21000d1b682"
        },
      {
          "name": "Dressing",
          "guid": "58F28218-2122-4F9E-8C4F-B0C300FAF09A"
      },
      {
          "name": "Eating and drinking",
          "guid": "703481A9-0DCC-4FCD-A283-B0C300FAF09A"
      },
      {
          "name": "Exercise",
          "guid": "BCBDA96A-2138-4F14-9A03-B0C300FAF09A"
      },
      {
          "name": "Housework/Domestic help",
          "guid": "359F2C8F-3689-4F46-90DA-B0C300FAF09A"
      },
      {
          "name": "Meal preparation",
          "guid": "9C3EDBE0-B5ED-44B8-8887-B0C300FAF09A"
      },
      {
          "name": "Medication management and administration",
          "guid": "D05127B9-F4F1-4143-9800-B0C300FAF09A"
      },
    
      {
          "name": "Paperwork/Form filling",
          "guid": "A732E58D-311D-42FD-B6EF-B0C300FAF09A"
      },
      {
          "name": "Personal care",
          "guid": "54B59DF6-7634-4DBA-8056-B0C300FAF09A"
      },
      {
          "name": "Pet care",
          "guid": "14F34F71-19C0-493F-B379-B0C300FAF09A"
      },
      {
          "name": "Support with family life",
          "guid": "07C2B872-E017-4D0A-B163-B0C300FAF09A"
      },
      {
          "name": "Support with social activities",
          "guid": "23F73D35-32D8-4A5B-A0BF-B0C300FAF09A"
      },
      {
          "name": "Toilet needs",
          "guid": "FEC8EE75-E512-47FE-B5DE-B0C300FAF09A"
      },
      {
          "name": "Transferring (Hoist/Manual)",
          "guid": "82E9AB87-7336-4CA0-9B56-B0C300FAF09A"
      },
      {
          "name": "Travel",
          "guid": "7BA8F7E9-876B-4313-AA8D-B0C300FAF09A"
      },
      {
        "name": "Other",
        "guid": "73FC8B40-9108-4A8A-8B0A-B0C300FAF09A"
      }
  ],

    ages:[
        {
          "name": "0-18",
          "guid": "D866046F-D640-44B9-A876-B0C300FB1775"
        },
        {
          "name": "18+",
          "guid": "C90B60B9-50B6-4EB0-B741-B0C300FB1775"
        }
    ],

    contract:[
        {
          "name": "Cover",
          "guid": "0C6CD75B-AE77-408A-AE50-B0C300F39A56"
        },
        {
          "name": "Permanent",
          "guid": "B0E98C7F-0797-4CA5-9F60-B0C300F39A56"
        },
        {
          "name": "Temporary",
          "guid": "E20C9E3D-6CC8-4751-AC21-B0C300F39A56"
        }
    ],

    day:[
        {
          "name": "Monday",
          "guid": "377B347B-A7BD-43E5-87D9-B0C300F4FC51"
        },
        {
          "name": "Tuesday",
          "guid": "0BE2A9A8-A6D2-4964-A4BB-B0C300F4FC51"
        },
        {
          "name": "Wednesday",
          "guid": "74EBD06F-9908-4419-B6B5-B0C300F4FC51"
        },
        {
          "name": "Thursday",
          "guid": "3ABDEDE3-6C1A-4ACB-AE44-B0C300F4FC51"
        },
        {
          "name": "Friday",
          "guid": "809F86A8-E28F-431B-B0DE-B0C300F4FC51"
        },
        {
          "name": "Saturday",
          "guid": "985D67C0-4341-4860-90E2-B0C300F4FC51"
        },
        {
          "name": "Sunday",
          "guid": "AB3EFEF1-9C22-48AF-88CC-B0C300F4FC51"
        }
    ],

    reference:[
        {
          "name": "I can supply a personal reference",
          "guid": "E98DBB25-1CF0-4352-97DB-B0C300EB5A2C"
        },
        {
          "name": "I can supply a professional reference",
          "guid": "A825275A-75EB-48F4-9A89-B0C300EB5A2C"
        }
    ],

    right:[
        {
          "name": "No",
          "guid": "20CA3A8B-503E-4BE2-8142-B0C300F06ACD"
        },
        {
          "name": "Yes",
          "guid": "C59BE451-20AA-4DFD-B5EF-B0C300F06ACD"
        }
    ],

    drivingLicence:[
        {
          "name": "No",
          "guid": "9D3C4495-EBF6-4517-B804-B0C300F03603"
        },
        {
          "name": "Yes",
          "guid": "11B5E5BC-E8AF-439D-A38C-B0C300F03603"
        }
    ],

    experience:[
      {
          "name": "Alzheimer's Disease/Dementia",
          "guid": "3E168B81-E672-4333-86B8-B0C300FBD726"
      },
      {
          "name": "Autism",
          "guid": "78DF5AA5-3AF2-4269-8F99-B0C300FBD726"
      },
      {
          "name": "Brain Injury",
          "guid": "70F802A0-3AA1-473D-8226-B0C300FBD726"
      },
      {
          "name": "Cerebral Palsy",
          "guid": "3ADF28BC-5D60-40EA-95F3-B0C300FBD726"
      },
      {
          "name": "Challenging Behaviour",
          "guid": "A1743B76-D466-4C1A-B020-B0C300FBD726"
      },
      {
          "name": "Communication Problems",
          "guid": "B246867F-027F-4FF9-AAC2-B0C300FBD726"
      },
      {
          "name": "Down Syndrome",
          "guid": "3AA69D94-BCB6-40D3-B818-B0C300FBD726"
      },
      {
          "name": "Epilepsy",
          "guid": "D1C1377E-7E9A-4E71-B7B3-B0C300FBD726"
      },
      {
          "name": "Learning Disability",
          "guid": "D1508D6A-A093-4C69-B013-B0C300FBD726"
      },
      {
          "name": "Mental Health",
          "guid": "712E285A-3209-4934-8832-B0C300FBD726"
      },
      {
          "name": "Mobility Impairments",
          "guid": "E0D39039-5492-4535-A371-B0C300FBD726"
      },
      {
          "name": "Parkinson's Disease",
          "guid": "9E0B6365-D386-4973-AC9B-B0C300FBD726"
      },
      {
          "name": "Spinal Injury",
          "guid": "1C0122CD-219A-4B7D-9204-B0C300FBD726"
      },
      {
          "name": "Stroke",
          "guid": "9BE23A6F-8DEA-4B2D-AD3E-B0C300FBD726"
      },
      {
          "name": "Visual Impairment",
          "guid": "469C70F7-BD42-4EF2-90DA-B0C300FBD726"
      },
      {
        "name": "Other",
        "guid": "34E464CE-13E5-4D17-8825-B0C300FBD726"
      }
  ],

    hours:[
        {
          "name": "Up to 16 hours",
          "guid": "75120E11-6F02-4BDB-80D8-B0C300F60BFE"
        },
        {
          "name": "16-24 hours",
          "guid": "7732E49A-205D-4CC6-A6B9-B0C300F60BFE"
        },
        {
          "name": "25-37 hours",
          "guid": "85425AE6-E6C7-4EC3-96C1-B0C300F60BFE"
        },
        {
          "name": "37-40 hours",
          "guid": "769E8915-40FC-47CC-9122-B0C300F60BFE"
        },
        {
          "name": "Other",
          "guid": "B63544C1-1E97-450A-8B94-B0C300F60BFE"
        }
    ],

    languages:[
      {
          "name": "Arabic",
          "guid": "92E62D71-2CDC-4207-A64B-B0C300FDE89E"
      },
      {
          "name": "British Sign Language",
          "guid": "9D419B70-11B2-477C-8A24-B0C300FDE89E"
      },
      {
          "name": "Chinese",
          "guid": "345767B8-6653-4BC9-AAAA-B0C300FDE89E"
      },
      {
          "name": "English",
          "guid": "5E1D3430-84BA-413B-8F18-B0C300FDE89E"
      },
      {
          "name": "Hindi",
          "guid": "DD926384-454A-4B03-BDE8-B0C300FDE89E"
      },
      {
          "name": "Makaton",
          "guid": "BD23D938-BB23-4410-8BE9-B0C300FDE89E"
      },
      {
          "name": "Nepalese",
          "guid": "E0748200-0D51-4A22-8B92-B0C300FDE89E"
      },
      {
          "name": "Polish",
          "guid": "96328519-1BFF-49EA-8E74-B0C300FDE89E"
      },
      {
          "name": "Portuguese",
          "guid": "E5080C38-6983-49C1-ACCA-B0C300FDE89E"
      },
      {
          "name": "Punjabi",
          "guid": "F53380C2-B73C-4D7E-933B-B0C300FDE89E"
      },
      {
          "name": "Spanish",
          "guid": "D45C35F3-6B76-4917-AC7B-B0C300FDE89E"
      },
      {
          "name": "Tamil",
          "guid": "F7812EB9-276C-4E83-87A1-B0C300FDE89E"
      },
      {
          "name": "Urdu",
          "guid": "085E78C6-BD12-4063-BFFE-B0C300FDE89E"
      },
      {
        "name": "Other",
        "guid": "9F707518-E674-4E17-AC3C-B0C300FDE89E"
      }
  ],

    // gender:[
    //     {
    //       "name": "Female",
    //       "guid": "8A58F81A-43D0-45D5-9B91-B13700D2F05E"
    //     },
    //     {
    //       "name": "Male",
    //       "guid": "42C25596-DDA4-4763-A38C-B13700D2F05E"
    //     },
    //     {
    //       "name": "Non-Binary",
    //       "guid": "A90C5680-4990-4EB4-B753-B13700D2F05E"
    //     },
    //     {
    //       "name": "Prefer not to say",
    //       "guid": "9216E971-377A-44A3-932E-B13700D2F05E"
    //     }
    // ],

    hobbies:[
      {
          "name": "Art and culture",
          "guid": "F3FF151B-DA4A-4610-BF9C-B0C300FEF8E2"
      },
      {
          "name": "Books and reading",
          "guid": "2F887EC7-A5C0-493D-BD07-B0C300FEF8E2"
      },
      {
          "name": "Cinema and movies",
          "guid": "6F6DF539-92C1-4850-9A41-B0C300FEF8E2"
      },
      {
          "name": "Computers",
          "guid": "ED7D5D6A-C580-4C34-9A34-B0C300FEF8E2"
      },
      {
          "name": "Cooking and baking",
          "guid": "F9F2392D-5DEF-4C52-8270-B0C300FEF8E2"
      },
      {
          "name": "Gardening",
          "guid": "0F03CDF8-2E10-42B3-9583-B0C300FEF8E2"
      },
      {
          "name": "Health and fitness",
          "guid": "19A6B715-03C3-432B-BF87-B0C300FEF8E2"
      },
      {
          "name": "Hobbies and crafts",
          "guid": "B3D1F84E-3FED-428B-BEFA-B0C300FEF8E2"
      },
      {
          "name": "Music and concerts",
          "guid": "8C37D582-24FB-4B73-9E93-B0C300FEF8E2"
      },
      {
          "name": "Outdoor activities",
          "guid": "F57A0AE9-5094-4540-A529-B0C300FEF8E2"
      },
      {
          "name": "Politics",
          "guid": "AFAE8974-D9AE-41DF-84C7-B0C300FEF8E2"
      },
      {
          "name": "Religion",
          "guid": "EB2A453E-3C83-4C68-8807-B0C300FEF8E2"
      },
      {
          "name": "Socialising",
          "guid": "A063A6C2-7F0E-40D6-B3D4-B0C300FEF8E2"
      },
      {
          "name": "Sports",
          "guid": "5405BAD4-B3FC-4F42-AB9E-B0C300FEF8E2"
      },
      {
          "name": "Travel",
          "guid": "937FAD93-1CE7-487A-9A37-B0C300FEF8E2"
      },
      {
          "name": "Video games",
          "guid": "EA71DD7C-6D02-4D9C-8832-B0C300FEF8E2"
      },
      {
          "name": "Wildlife and nature",
          "guid": "46E620B5-812C-4A98-8A61-B0C300FEF8E2"
      },
      {
        "name": "Other",
        "guid": "F4716107-0196-4D4A-878F-B0C300FEF8E2"
      }
  ],

    qualities:[
      {
          "name": "Cheerful",
          "guid": "69136712-B2F6-464E-90B7-B0C300FFC57C"
      },
      {
          "name": "Competent driver",
          "guid": "C7EBFAFA-55DF-4E7E-AC14-B0C300FFC57C"
      },
      {
          "name": "Conscientious",
          "guid": "6181951F-5FFF-452F-9451-B0C300FFC57C"
      },
      {
          "name": "Empathetic",
          "guid": "01C72637-D6B7-4847-B932-B0C300FFC57C"
      },
      {
          "name": "Fast learner",
          "guid": "DDB309F1-D751-4243-B8C6-B0C300FFC57C"
      },
      {
          "name": "Flexible",
          "guid": "CE0E613E-9CA2-458A-9661-B0C300FFC57C"
      },
      {
          "name": "Good cook",
          "guid": "7BC2A71D-7372-4A96-B866-B0C300FFC57C"
      },
      {
          "name": "Good with animals",
          "guid": "D5948443-2B69-4149-96D6-B0C300FFC57C"
      },
      {
          "name": "Good with children",
          "guid": "6B069928-8301-4973-8B57-B0C300FFC57C"
      },
      {
          "name": "Good with time keeping",
          "guid": "74E62F84-EA51-4FC1-A877-B0C300FFC57C"
      },
      {
          "name": "Kind",
          "guid": "2BCB12A3-E95D-431B-92F2-B0C300FFC57C"
      },
      {
          "name": "Outgoing",
          "guid": "4281609A-E7C4-471A-A155-B0C300FFC57C"
      },
      {
          "name": "Patient",
          "guid": "91F9118D-2B00-4999-AC24-B0C300FFC57C"
      },
      {
          "name": "Reliable",
          "guid": "39B63FD4-2F06-4772-8098-B0C300FFC57C"
      },
      {
          "name": "Resourceful",
          "guid": "7D856F77-0675-48DF-88FE-B0C300FFC57C"
      },
      {
          "name": "Sense of humour",
          "guid": "BFE54F5B-0D73-4D08-9217-B0C300FFC57C"
      },
      {
        "name": "Other",
        "guid": "9D104177-6FF9-4631-AECA-B0C300FFC57C"
      }
  ],

    team:[
        {
          "name": "No",
          "guid": "BE609D19-7F01-4409-8241-B0C30100B0B8"
        },
        {
          "name": "Yes",
          "guid": "CC11BD0C-8C12-4EC4-8BAC-B0C30100B0B8"
        }
    ]    
}